import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard-checklists.default',
        path: `${APP_PREFIX_PATH}/dashboards-checklists/default`,
        component: React.lazy(() => import('views/app-views/dashboards-checklists/default')),
    },
    {
        key: 'cliente.default',
        path: `${APP_PREFIX_PATH}/cliente/default`,
        component: React.lazy(() => import('views/app-views/cliente/default')),
    },
    {
        key: 'cliente.default',
        path: `${APP_PREFIX_PATH}/veiculo/default`,
        component: React.lazy(() => import('views/app-views/veiculo/default')),
    },
    {
        key: 'cliente.default',
        path: `${APP_PREFIX_PATH}/rastreador/default`,
        component: React.lazy(() => import('views/app-views/rastreador/default')),
    },
    {
        key: 'cliente.default',
        path: `${APP_PREFIX_PATH}/contrato/default`,
        component: React.lazy(() => import('views/app-views/contrato/default')),
    },
    {
        key: 'checklist.default',
        path: `${APP_PREFIX_PATH}/checklist/default`,
        component: React.lazy(() => import('views/app-views/checklist/default')),
    },
    {
        key: 'push.default',
        path: `${APP_PREFIX_PATH}/push/default`,
        component: React.lazy(() => import('views/app-views/push/default')),
    },
    {
        key: 'sms.default',
        path: `${APP_PREFIX_PATH}/sms/default`,
        component: React.lazy(() => import('views/app-views/sms/default')),
    },
    {
        key: 'whatsapp.default',
        path: `${APP_PREFIX_PATH}/whatsapp/default`,
        component: React.lazy(() => import('views/app-views/whatsapp/default')),
    },
    {
        key: 'ses.default',
        path: `${APP_PREFIX_PATH}/ses/default`,
        component: React.lazy(() => import('views/app-views/ses/default')),
    },
    {
        key: 'config.default',
        path: `${APP_PREFIX_PATH}/configuracao/default`,
        component: React.lazy(() => import('views/app-views/configuracao/default')),
    },
    {
        key: 'boleto.default',
        path: `${APP_PREFIX_PATH}/boleto/default`,
        component: React.lazy(() => import('views/app-views/boleto/default')),
    },
    {
        key: 'instalador.default',
        path: `${APP_PREFIX_PATH}/instalador/default`,
        component: React.lazy(() => import('views/app-views/instalador/default')),
    }
]