import fetch from 'auth/FetchInterceptor'

const checklistService = {}

checklistService.all = function () {
  return fetch({
    url: '/checklist/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

checklistService.allPendentes = function () {
  return fetch({
    url: '/checklist/pendentes',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

checklistService.id = function (data) {
  return fetch({
    url: `/checklist/${data}/obter`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

checklistService.criar = function (data) {
  return fetch({
    url: `/checklist/contrato/${data}/criar/instalacao`,
    method: 'post',
    data: data
  })
}

checklistService.criarManutencao = function (data) {
  return fetch({
    url: `/checklist/contrato/${data}/criar/manutencao`,
    method: 'post',
    data: data
  })
}

checklistService.criarRemocao = function (data) {
  return fetch({
    url: `/checklist/contrato/${data}/criar/remocao`,
    method: 'post',
    data: data
  })
}

// Nova função para salvar checklist
checklistService.save = function (data) {
  return fetch({
    url: '/checklist/save',
    method: 'post',
    data: data
  })
}

// Nova função para atualizar um checklist existente
checklistService.update = function (data) {
  return fetch({
    url: `/checklist/update`,
    method: 'put',
    data: data
  })
}

// Nova função para enviar mensagem para o instalador via WhatsApp
checklistService.enviarParaInstalador = function (id) {
  return fetch({
    url: `/checklist/${id}/enviar`,
    method: 'post'
  })
}

checklistService.enviarParaInstaladorApenas = function (id) {
  return fetch({
    url: `/checklist/${id}/enviar-instalador`,
    method: 'post'
  })
}

checklistService.enviarParaFinalizado = function (id) {
  return fetch({
    url: `/checklist/${id}/finalizar`,
    method: 'post'
  })
}

// Nova função para enviar lista de checklists para o instalador
checklistService.enviarListaParaInstalador = function (id) {
  return fetch({
    url: `/checklist/instalador/${id}/enviar-lista`,
    method: 'post'
  })
}

export default checklistService