import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import cliente from './slices/clienteSlice'
import veiculo from './slices/veiculoSlice'
import rastreador from './slices/rastreadorSlice'
import contrato from './slices/contratoSlice'
import sms from './slices/smsSlice'
import ses from './slices/sesSlice'
import configuracao from './slices/configuracaoSlice'
import boleto from './slices/boletoSlice'
import empresa from './slices/empresaSlice'
import relatorio from './slices/relatorioSlice'
import financeiro from './slices/financeiroSlice'
import whatsapp from './slices/whatsappSlice'
import checklist from './slices/checklistSlice'
import cobrancaUsuario from './slices/cobrancaUsuarioSlice'
import push from './slices/pushSlice'
import instalador from './slices/instaladorSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        cliente,
        veiculo,
        rastreador,
        contrato,
        sms,
        ses,
        configuracao,
        boleto,
        empresa,
        relatorio,
        financeiro,
        whatsapp,
        checklist,
        cobrancaUsuario,
        push,
        instalador,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
