import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import ChecklistService from 'services/ChecklistService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('checklist/all',async () => {
    const response = await ChecklistService.all()
    return response;
})

export const getAllPendentes = createAsyncThunk('checklist/pendentes',async () => {
    const response = await ChecklistService.allPendentes()
    return response;
})

export const criar = createAsyncThunk('checklist/criar',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.criar(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar checklist')
	}
})

export const criarManutencao = createAsyncThunk('checklist/criarManutencao',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.criarManutencao(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar manutencao de checklist')
	}
})

export const criarRemocao = createAsyncThunk('checklist/criarRemocao',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.criarRemocao(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar remocao de checklist')
	}
})

export const enviarInstalador = createAsyncThunk('checklist/enviar',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.enviarParaInstalador(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar remocao de checklist')
	}
})

export const enviarInstaladorApenas = createAsyncThunk('checklist/enviarInstalador',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.enviarParaInstaladorApenas(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar remocao de checklist')
	}
})

export const enviarParaFinalizado = createAsyncThunk('checklist/finalizado',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.enviarParaFinalizado(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao criar remocao de checklist')
	}
})

export const getId = createAsyncThunk('checklist/id',async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao obter checklist')
	}
})

// Nova action para salvar checklist
export const save = createAsyncThunk('checklist/save',async (data, { rejectWithValue }) => {
	try {
		const response = data.id 
			? await ChecklistService.update(data) 
			: await ChecklistService.save(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar checklist')
	}
})

// Nova action para enviar lista de checklists para o instalador
export const enviarListaParaInstalador = createAsyncThunk('checklist/enviarLista', async (data, { rejectWithValue }) => {
	try {
		const response = await ChecklistService.enviarListaParaInstalador(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao enviar lista de checklists para o instalador')
	}
})

export const checklistService = createSlice({
	name: 'checklist',
	initialState,
	reducers: {
		clearChecklistEdicao: (state) => {
			state.edicao = null;
		},
	},
    extraReducers: (builder) => {
		builder
		.addCase(getAll.pending, (state) => {
			state.loading = true
		})
		.addCase(getAll.fulfilled, (state, action) => {
			state.loading = false
			state.checklists = action.payload
		})
		.addCase(getAll.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getAllPendentes.pending, (state) => {
			state.loading = true
		})
		.addCase(getAllPendentes.fulfilled, (state, action) => {
			state.loading = false
			state.checklists = action.payload
		})
		.addCase(getAllPendentes.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(getId.pending, (state) => {
			state.loading = true
		})
		.addCase(getId.fulfilled, (state, action) => {
			state.loading = false
			state.checklistId = action.payload
			state.edicao = action.payload
		})
		.addCase(getId.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(save.pending, (state) => {
			state.loading = true
		})
		.addCase(save.fulfilled, (state, action) => {
			state.loading = false
			state.edicao = null
		})
		.addCase(save.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
		.addCase(enviarListaParaInstalador.pending, (state) => {
			state.loading = true
		})
		.addCase(enviarListaParaInstalador.fulfilled, (state, action) => {
			state.loading = false
		})
		.addCase(enviarListaParaInstalador.rejected, (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		})
	},
})

export const {
	showLoading,
	clearChecklistEdicao
} = checklistService.actions

export default checklistService.reducer