import fetch from 'auth/FetchInterceptor'

const instaladorService = {}

instaladorService.all = function () {
  return fetch({
    url: '/instalador/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

instaladorService.save = function (data) {
  return fetch({
    url: '/instalador',
    method: 'post',
    data: data
  })
}

instaladorService.id = function (data) {
  return fetch({
    url: `/instalador/${data}/id`,
    method: 'get',
    data: data
  })
}

instaladorService.inativar = function (data) {
  return fetch({
    url: `/instalador/${data}/inativar`,
    method: 'put',
    data: data
  })
}

export default instaladorService