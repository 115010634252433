import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import InstaladorService from 'services/InstaladorService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const getAll = createAsyncThunk('instalador/all',async () => {
    const response = await InstaladorService.all()
    return response;
})


export const save = createAsyncThunk('instalador/save',async (data, { rejectWithValue }) => {
	try {
		const response = await InstaladorService.save(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao salvar instalador')
	}
})

export const id = createAsyncThunk('instalador/id',async (data, { rejectWithValue }) => {
	try {
		const response = await InstaladorService.id(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao buscar instalador')
	}
})

export const inativar = createAsyncThunk('instalador/inativar',async (data, { rejectWithValue }) => {
	try {
		const response = await InstaladorService.inativar(data);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Erro ao inativar instalador')
	}
})

export const instaladorSlice = createSlice({
	name: 'instalador',
	initialState,
	reducers: {
		clearInstaladorEdicao: (state) => {
			state.edicao = null;
		  },
	},
    extraReducers: (builder) => {
		builder
			.addCase(getAll.pending, (state) => {
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				state.instaladores = action.payload
			})
			.addCase(getAll.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(save.pending, (state) => {
				state.loading = true
			})
			.addCase(save.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/instalador/default'
				state.edicao = null
			})
			.addCase(save.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(id.pending, (state) => {
				state.loading = true
			})
			.addCase(id.fulfilled, (state, action) => {
				state.loading = false
				state.edicao = action.payload
			})
			.addCase(id.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(inativar.pending, (state) => {
				state.loading = true
			})
			.addCase(inativar.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/app/instalador/default'
				state.edicao = action.payload
			})
			.addCase(inativar.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	showLoading,
	clearInstaladorEdicao
} = instaladorSlice.actions

export default instaladorSlice.reducer