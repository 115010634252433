import fetch from 'auth/FetchInterceptor'

const boletoService = {}

boletoService.all = function () {
  return fetch({
    url: '/boleto/all',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

boletoService.atrasado = function () {
  return fetch({
    url: '/boleto/atraso',
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

boletoService.getByContrato = function (contratoId) {
  return fetch({
    url: `/boleto/${contratoId}/contrato`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
      },
  })
}

boletoService.cancelar = function (data) {
  return fetch({
    url: `/boleto/${data}/cancelar`,
    method: 'delete',
    data: data
  })
}

boletoService.reenviar = function (data) {
  return fetch({
    url: `/boleto/${data}/reenviar`,
    method: 'post',
    data: data
  })
}

boletoService.confirmar = function (data) {
  return fetch({
    url: `/boleto/${data}/confirmar-pagamento`,
    method: 'post',
    data: data
  })
}

boletoService.gerarNf = function (data) {
  return fetch({
    url: `/boleto/${data}/gerar-nf`,
    method: 'post',
    data: data
  })
}


export default boletoService